import React from 'react';
import '../CSS/Footer.css';

const Footer = () => {

  return (
    <div className='footer-container'>
        <p className="slogan"> Don't Be A Tourist!</p>
   </div>
  );
}

export default Footer;