import React from 'react';
import '../CSS/WelcomeMessage.css'; // Updated CSS file path
import Me1 from '../Images/Welcome/Me1.jpeg'; 
import Me2 from '../Images/Welcome/Me2.jpeg'; 
import Me3 from '../Images/Welcome/Me3.jpeg'; 
import Me4 from '../Images/Welcome/Me4.jpeg'; 
import Baby from '../Images/Welcome/Baby.png'; 
import { getStaticProps } from '../Utils/getStaticProps'; 

const WelcomeMessage = ({ images }) => {
  console.log('images', images)
  return (
    <React.Fragment>
      <div className='welcome-title'> {/* Updated class name */}
        About Me! 
      </div>
      <div className='welcome-container'> {/* Updated class name */}
        <div className='about-me-container'> {/* Updated class name */}
          <div className='welcome-text'> {/* Updated class name */}
          Hey there, welcome to my website! I'm Rohan, a photographer based in Washington. Photography has been my passion for as long as I can remember, starting with exploring my dad's camera.

          This website is where I share my work and vision. If you're interested in prints or scheduling a shoot, I'd love to connect! Just head over to the Contact Me page.
          </div>
          <div className='baby-photo'> 
            <img src={Baby} alt="Baby" />
          </div>
        </div>
        <div className='unique-photos-of-me-container'> {/* Updated class name */}
          <div className="unique-image-container Me1"> {/* Updated class name */}
            <img src={Me1} alt="Me1" />
            <div className="unique-image-overlay">Taken by Yash</div> {/* Updated class name */}
          </div>
          <div className="unique-image-container Me2"> {/* Updated class name */}
            <img src={Me2} alt="Me2" />
            <div className="unique-image-overlay">Taken by Yash</div> {/* Updated class name */}
          </div>
          <div className="unique-image-container Me3"> {/* Updated class name */}
            <img src={Me3} alt="Me3" />
            <div className="unique-image-overlay">Taken by Bhavik</div> {/* Updated class name */}
          </div>
          <div className="unique-image-container Me4"> {/* Updated class name */}
            <img src={Me4} alt="Me4" />
            <div className="unique-image-overlay">Taken by Bhargav</div> {/* Updated class name */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WelcomeMessage;

export { getStaticProps };