import React, { useState } from 'react';
import '../CSS/ContactMe.css';
import emailjs from 'emailjs-com'; // Ensure you have installed emailjs-com package

const ContactForm = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: ''
  }); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Check if any of the fields are empty
    if (!formData.user_name || !formData.user_email || !formData.message) {
      setStateMessage('Please fill out all fields');
      return; // Exit the function early if any field is empty
    }

    setIsSubmitting(true);

    emailjs
      .sendForm(
        'service_56574he', // Your service ID
        'template_yvre1uv', // Your template ID
        e.target,
        'ydExWaaa2WqRb4JlY' // Your account public key
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    setFormData({
      user_name: '',
      user_email: '',
      message: ''
    });
  };

  return (
    <div className='contact-container'>
      <div className='title-contactme'> 
        Contact Me
      </div>
      <form onSubmit={sendEmail} className="contact-form">
        <label className="contact-form__label">Name</label>
        <input
          type="text"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
          className="contact-form__input"
          placeholder="Enter your name" // Placeholder text for Name field
        />
        <label className="contact-form__label">Email</label>
        <input
          type="email"
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
          className="contact-form__input"
          placeholder="Enter your email" // Placeholder text for Email field
        />
        <label className="contact-form__label">Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="contact-form__input contact-form__textarea"
          placeholder="Type your message here" // Placeholder text for Message field
        />
        <input type="submit" value="Send" disabled={isSubmitting} className="contact-form__submit" />
        {stateMessage && <p className={`contact-form__message ${stateMessage.includes('Something went wrong') ? 'contact-form__error' : 'contact-form__success'}`}>{stateMessage}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
