import React, { useState, useEffect } from 'react';
import '../CSS/NavBar.css';
import MobileNavbar from './MobileNavbar'; // Import MobileNavbar component
import { Link } from "react-router-dom";
import Logo from '../Images/Logo.png'; 
import { BsCart3 } from "react-icons/bs";

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMobileNavbar = () => {
        setIsMobileNavbarOpen(prevState => !prevState); // Toggle the state
    };

    return (
        <div>
            {isMobile && (
                <MobileNavbar 
                    isMobileNavbarOpen={isMobileNavbarOpen}
                    toggleMobileNavbar={toggleMobileNavbar}
                />
            )}
            {!isMobile && (
                <div className="nav-container">
                    <div className='logo-container'> 
                        <Link to="/WelcomeMessage" className="logo">
                            <img src={Logo} alt="Logo" className="logo" />
                        </Link>
                    </div>
                    <div className={`pagelink-container ${isMobileNavbarOpen ? 'open' : ''}`}> 
                        <Link to="/Landscape" className="link-name">
                            Landscape
                        </Link>
                        <Link to="/Car" className="link-name">
                            Car
                        </Link>
                        <Link to="/Portrait" className="link-name">
                            Portrait
                        </Link>
                        <Link to="/ContactMe" className="link-name">
                            Contact Me
                        </Link>
                    </div>
                    <div className={`shopping-cart-container ${isMobileNavbarOpen ? 'open' : ''}`}> 
                        <Link to="/Shop" className='cart-icon'>
                            <BsCart3 />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
