import React from 'react';
import '../CSS/MobileNavbar.css'; // Import the new CSS file
import { Link } from "react-router-dom";
import Logo from '../Images/Logo.png'; 
import { BsList, BsCart3 } from "react-icons/bs";

const MobileNavbar = ({ isMobileNavbarOpen, toggleMobileNavbar }) => {
    return (
        <div className="mobile-navbar-container">
            <div className="mobile-navbar-header">
                <Link to="/WelcomeMessage" className="mobile-navbar-logo">
                    <img src={Logo} alt="Logo" className="logo" />
                </Link>
                <div className="menu-button" onClick={toggleMobileNavbar}>
                    <BsList className="list-icon" />
                </div>
            </div>
            {isMobileNavbarOpen && (
                <div className="mobile-navbar-links">
                    <Link to="/Landscape" className="mobile-navbar-link">
                        Landscape
                    </Link>
                    <Link to="/Car" className="mobile-navbar-link">
                        Car
                    </Link>
                    <Link to="/Portrait" className="mobile-navbar-link">
                        Portrait
                    </Link>
                    <Link to="/ContactMe" className="mobile-navbar-link">
                        Contact Me
                    </Link>
                    <Link to="/Shop" className="mobile-navbar-link">
                        <BsCart3 className="mobile-navbar-cart-icon" />
                    </Link>
                </div>
            )}
        </div>
    );
}

export default MobileNavbar;
