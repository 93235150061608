import React from 'react';
import '../CSS/Shop.css';
import one from '../Images/Shop/1.jpg'; 

const Shop = () => {

  return (
   <div className='main-container'>
    <div className='title-shop'>
      Shop Coming Soon!
    </div>
    <div className="image-container-landscape one">
            <img src={one} alt="one" />
            <div className="image-overlay-landscape">Could be yours wink wink</div>
    </div>
   </div>
  );
}

export default Shop;