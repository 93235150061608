import React from 'react';
import '../CSS/Landscape.css';

import one from '../Images/Landscape/1.jpg'; 
import two from '../Images/Landscape/2.jpg'; 
import three from '../Images/Landscape/3.jpg'; 
import onefive from '../Images/Landscape/4.jpg'; 
import five from '../Images/Landscape/5.jpg'; 
import six from '../Images/Landscape/6.jpg'; 
import seven from '../Images/Landscape/7.jpg'; 
import eight from '../Images/Landscape/8.jpg'; 
import nine from '../Images/Landscape/9.jpg'; 
import ten from '../Images/Landscape/10.jpg'; 
import oneone from '../Images/Landscape/11.jpg'; 
import onetwo from '../Images/Landscape/12.jpg'; 
import onethree from '../Images/Landscape/13.jpg'; 
import onefour from '../Images/Landscape/14.jpg'; 
import four from '../Images/Landscape/15.jpg'; 
import onesix from '../Images/Landscape/16.jpg'; 
import oneseven from '../Images/Landscape/17.jpg'; 
import oneeight from '../Images/Landscape/18.jpg'; 
import onenine from '../Images/Landscape/19.jpg'; 
import twozero from '../Images/Landscape/20.jpg'; 
import twotwo from '../Images/Landscape/22.jpg'; 
import twothree from '../Images/Landscape/23.jpg'; 


const Landscape = () => {

  return (
    <div className='main-landscape'>
      <div className='title-landscape'>
        Landscape
      </div>

      <div className='photo-container-landscape'> 
        <div className="image-container-landscape one">
            <img src={one} alt="one" />
            <div className="image-overlay-landscape">Big Island</div>
        </div>
        <div className="image-container-landscape two">
            <img src={two} alt="two" />
            <div className="image-overlay-landscape">Oregon Coast</div>
        </div>
        <div className="image-container-landscape three">
            <img src={three} alt="three" />
            <div className="image-overlay-landscape">Oregon Coast</div>
        </div>
        <div className="image-container-landscape four">
            <img src={four} alt="four" />
            <div className="image-overlay-landscape">New York</div>
        </div>
        <div className="image-container-landscape five">
            <img src={five} alt="five" />
            <div className="image-overlay-landscape">Dubai</div>
        </div>
        <div className="image-container-landscape six">
            <img src={six} alt="six" />
            <div className="image-overlay-landscape">Yosemite</div>
        </div>
        <div className="image-container-landscape seven">
            <img src={seven} alt="seven" />
            <div className="image-overlay-landscape">Yellowstone</div>
        </div>
        <div className="image-container-landscape eight">
            <img src={eight} alt="eight" />
            <div className="image-overlay-landscape">Olympic</div>
        </div>
        <div className="image-container-landscape nine">
            <img src={nine} alt="nine" />
            <div className="image-overlay-landscape">Grand Teton</div>
        </div>
        <div className="image-container-landscape ten">
            <img src={ten} alt="ten" />
            <div className="image-overlay-landscape">Seattle</div>
        </div>
        <div className="image-container-landscape oneone">
            <img src={oneone} alt="oneone" />
            <div className="image-overlay-landscape">New York</div>
        </div>
        <div className="image-container-landscape onetwo">
            <img src={onetwo} alt="onetwo" />
            <div className="image-overlay-landscape">Olympic</div>
        </div>
        <div className="image-container-landscape onefour">
            <img src={onethree} alt="onethree" />
            <div className="image-overlay-landscape">Big Island</div>
        </div>
        <div className="image-container-landscape onefour">
            <img src={onefour} alt="onefour" />
            <div className="image-overlay-landscape">North Cascades</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={onefive} alt="onefive" />
            <div className="image-overlay-landscape">Dubai</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={onesix} alt="onesix" />
            <div className="image-overlay-landscape">Central Cascades</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={oneseven} alt="oneseven" />
            <div className="image-overlay-landscape">Yellowstone</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={oneeight} alt="oneeight" />
            <div className="image-overlay-landscape">Yellowstone</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={onenine} alt="onenine" />
            <div className="image-overlay-landscape">Big Island</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={twozero} alt="twozero" />
            <div className="image-overlay-landscape">Olympic</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={twotwo} alt="twotwo" />
            <div className="image-overlay-landscape">North Cascades</div>
        </div>
        <div className="image-container-landscape onefive">
            <img src={twothree} alt="twothree" />
            <div className="image-overlay-landscape">Mount Rainier</div>
        </div>


      </div>
      
   </div>
  );
}

export default Landscape;
